import React from 'react';
import Helmet from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Link from '@nib-components/link';
import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import Copy, { Bold } from '@nib-components/copy';
import Accordion from '@nib-components/accordion';
import { PrimaryButton } from '@nib-components/button';
import { Stack, Box, Section, Padding } from '@nib/layout';
import { colorTrueGreen } from '@nib-components/theme';

import HeroPanel from '../components/HeroPanel';

import Layout from '../components/Layout';
import metrics from '../metrics';

import desktop from '../img/tenehenehenui/hero/desktop.png';
import desktop2x from '../img/tenehenehenui/hero/desktop@2x.png';
import mobile from '../img/tenehenehenui/hero/mobile.png';
import mobile2x from '../img/tenehenehenui/hero/mobile@2x.png';
import tablet from '../img/tenehenehenui/hero/tablet.png';
import tablet2x from '../img/tenehenehenui/hero/tablet@2x.png';

import insuranceOverview from '../img/tenehenehenui/insurance-overview.jpg';
import memberCard from '../img/tenehenehenui/member-card.jpg';
import mobileApp from '../img/tenehenehenui/mobile-app.png';
import korero from '../img/tenehenehenui/korero.jpg';

import { BackgroundImageWrapper, ContentWrapper, Wrapper } from '../components/styledComponents';
import { urlConstants } from '../constructs/constants';
import { ContentfulDocument } from '../constructs/models';

export const NwoPageQuery = graphql`
  query NwoPageQuery {
    policy: allContentfulDocument(filter: { name: { eq: "TNN Policy Document" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

// TODO replace this with a color from mesh when and if it ever becomes available
// ref: https://www.meshdesignsystem.com/foundations/colors/#warm-white-tints
// see: https://github.com/nib-group/design-system/blob/master/packages/layout/src/Box/lib/colors.ts#L55
const WarmWhite90 = '#fff2df';
const Darkest = '#434343';

const NotQuiteWarmWhiteColorSectionBecauseHaveToMatchAvailableImageColorThatsIsNotInMesh = styled(
  Section
)`
  background-color: ${WarmWhite90} !important;
`;

const CopyDarkest = styled(Copy)`
  color: ${Darkest} !important;
`;

const title = 'Te Nehenehenui Health Insurance';
const metaDescription = 'The Te Nehenehenui Health Insurance, which is being provided.';

interface GraphQLNwoDocumentNode {
  edges: ContentfulNwoDocumentEdgeShape[];
}
interface ContentfulNwoDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface NwoDocumentProps {
  data: {
    brochure: GraphQLNwoDocumentNode;
    policy: GraphQLNwoDocumentNode;
  };
}

interface NWOProps extends RouteComponentProps, NwoDocumentProps {}

interface WithPolicyDocSectionProps {
  policyDocument: ContentfulDocument;
}

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title={
        <>
          Benefits of <NoWrap>Te Nehenehenui</NoWrap> Everyday Health Plan
        </>
      }
      subTitle={
        <Stack space={4}>
          <Copy>Enrol now to get access to this health plan from 1 April 2025</Copy>
          <PrimaryButton href="/tenehenehenui/enrol">Enrol now</PrimaryButton>
        </Stack>
      }
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    />
  );
};

const InsuranceOverviewSection = (): JSX.Element => (
  <Section>
    <Wrapper>
      <BackgroundImageWrapper backgroundImage={insuranceOverview} backgroundPosition="top" />
      <ContentWrapper isOnRight={true}>
        <Stack space={{ xs: 2, md: 4 }}>
          <Box marginBottom={4}>
            <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
              Te Nehenehenui Everyday Health Plan Overview
            </Heading>
          </Box>
          <Copy measure={false}>
            <strong>Working together for Maniapoto whānau, hapū, iwi wellbeing.</strong>
          </Copy>
          <Copy measure={false}>
            Te Nehenehenui is committed to improve the wellbeing of their people and they’re proud
            to partner with nib in this important mahi to make healthcare more accessible to those
            who need it most. The Te Nehenehenui Everyday Health Plan helps you take care of your
            everyday health expenses, so you can focus on what matters most — spending time with
            whānau, being involved in your community and enjoying life.
          </Copy>
          <Copy measure={false}>
            While this health insurance is available at no cost during the first year for Maniapoto
            kaumātua aged 65 and over, a heavily subsidised insurance package will be available
            thereafter. In addition, other whānau members registered with Te Nehenehenui and holding
            a Maniapoto registration number can access the Te Nehenehenui Everyday Health Plan at
            their own cost. This is an opportunity for everyone in the wider whānau to take part in
            a holistic approach to wellbeing.
          </Copy>
        </Stack>
      </ContentWrapper>
    </Wrapper>
  </Section>
);

const WhatThePlanIncludesSection = ({ policyDocument }: WithPolicyDocSectionProps): JSX.Element => (
  <Section background="sageGreen60">
    <Stack space={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
        What the plan includes
      </Heading>
      <Copy measure={false}>
        With the Te Nehenehenui Everyday Health Plan, each insured person will have access to:{' '}
        <sup>1</sup>
      </Copy>
      <Copy measure={false} component="div">
        <ul>
          <Stack space={2}>
            <li>Up to $500 per year for general and preventive dental treatment</li>
            <li>Up to $250 per year for prescription glasses and contact lenses</li>
            <li>Up to $350 per year for GP and nurse practitioner consultations </li>
            <li>Up to $300 per year for pharmaceutical prescriptions </li>
            <li>Up to $500 per year for Rongoā Māori treatments</li>
            <li>Up to $500 per year for vaccinations </li>
            <li>Up to $100 per year for physiotherapy treatments </li>
            <li>Up to $300 per year for dietician and nutritionist consultations </li>
            <li>Up to $40 per visit and up to $250 per year for podiatry treatments </li>
            <li>
              Up to $40 per visit and up to $250 per year for osteopathy and up to $80 per year for
              x-rays{' '}
            </li>
            <li>Up to $250 for audiology tests and up to $250 for audiology treatment per year </li>
            <li>A $3,000 tangihanga cash support benefit made to the estate</li>
          </Stack>
        </ul>
      </Copy>
      <Copy measure={false}>
        <sup>1</sup>
        This overview outlines the main features and benefits of the Te Nehenehenui Everyday Health
        Plan policy. For a full explanation of the benefits and exclusions, please see the{' '}
        <Link href={policyDocument?.file?.url} target="_blank">
          Te Nehenehenui Everyday Health Plan policy document
        </Link>
        .
      </Copy>
      <Copy measure={false}>Waiting periods may apply for the first year of your policy. </Copy>
    </Stack>
  </Section>
);

const JoinUpNowSection = (): JSX.Element => {
  return (
    <Section>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
          Join now
        </Heading>
        <Accordion>
          <StyledAccordionItem title="How do I enrol?" titleComponent="p">
            <Copy measure={false}>
              Simply click the 'Enrol now' button at the top of this page or visit{' '}
              <Link href="/tenehenehenui/enrol">www.nib.co.nz/tenehenehenui/enrol</Link>.
            </Copy>
          </StyledAccordionItem>
          <StyledAccordionItem
            title="What's different about enrolling if I'm over or under 65?"
            titleComponent="p"
          >
            <Copy measure={false}>
              <ul>
                <li>
                  Over 65 - After enrolment, Te Nehenehenui will pay for your first full year of
                  cover and, thereafter will heavily subsidise future payments.
                </li>
                <li>Under 65 - You'll need to set up a direct debit to pay your monthly premium</li>
              </ul>
            </Copy>
          </StyledAccordionItem>
          <StyledAccordionItem title="What does it cost?" titleComponent="p">
            <Copy measure={false}>
              <ul>
                <li>Adults (21 and over) - $46.25 per month </li>
                <li>
                  Children (under 21) - $22.10 per month
                  <p>
                    <strong>Note</strong>: Final amounts may vary by a few cents.
                  </p>
                </li>
              </ul>
            </Copy>
          </StyledAccordionItem>
          <StyledAccordionItem title="Why do you need my GP's/Doctor’s details?" titleComponent="p">
            <Copy measure={false}>
              This helps us connect your GP to our provider portal, making it easier for you to
              claim. When your GP is connected, they can claim directly through us when you visit.{' '}
            </Copy>
          </StyledAccordionItem>
          <StyledAccordionItem title="What happens after I enrol?" titleComponent="p">
            <Copy measure={false}>
              <ol>
                <li>
                  Te Nehenehenui will check your Maniapoto Membership registration and confirm your
                  eligibility.
                </li>
                <li>We'll email your welcome pack before your cover starts.</li>
              </ol>
            </Copy>
          </StyledAccordionItem>
        </Accordion>
      </Stack>
    </Section>
  );
};

const NibAppSection = (): JSX.Element => {
  return (
    <Section background="sageGreen60">
      <Wrapper>
        <BackgroundImageWrapper
          backgroundImage={mobileApp}
          backgroundPosition="top"
          backgroundSize="contain"
          backgroundColor="#dae6d3"
        />
        <ContentWrapper isOnRight={true}>
          <Stack space={{ xs: 2, md: 4 }}>
            <Box marginBottom={4}>
              <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
                Making it easy to use your cover
              </Heading>
            </Box>
            <Bold measure={false}>
              Take control of your health journey with the my nib app, where you can:
            </Bold>
            <ul>
              <li>Make claims</li>
              <li>Complete your HealthCheck and get personalised recommendations </li>
              <li>Check your symptoms through our virtual health assessment </li>
              <li>View your available benefit limits </li>
              <li>Track your claims</li>
            </ul>
            <Copy measure={false}>
              Download my nib through{' '}
              <Link href={'https://apps.apple.com/nz/app/my-nib-nz/id968982318'}>
                Apple's App Store
              </Link>{' '}
              or{' '}
              <Link
                href={
                  'https://play.google.com/store/apps/details?id=nz.co.nib.mynib&hl=en_NZ&pli=1'
                }
              >
                Google Play
              </Link>
              .
            </Copy>

            <Bold>Claiming using your nib app:</Bold>
            <ol>
              <li>Start your claim </li>
              <li>Take a photo of your invoice </li>
              <li>Add claim details </li>
              <li>Add your refund information </li>
              <li>Submit </li>
            </ol>

            <Copy measure={false}>
              Watch our helpful video on{' '}
              <Link href={'https://youtu.be/XABb09azjg8'}>making a claim</Link>.{' '}
            </Copy>

            <Bold>Checking your benefit limits:</Bold>
            <ul>
              <li>
                Open the my nib app, and go to 'My Usage' to see:
                <ul>
                  <li>What benefits you have</li>
                  <li>How much of the benefits you've used </li>
                  <li>How much of the benefits you have left </li>
                </ul>
              </li>
            </ul>
          </Stack>
        </ContentWrapper>
      </Wrapper>
    </Section>
  );
};

const MemberCardContent = (): JSX.Element => {
  return (
    <Wrapper>
      <BackgroundImageWrapper
        backgroundImage={memberCard}
        backgroundColor={'none'}
        backgroundSize={'contain'}
      />
      <ContentWrapper isOnRight={true}>
        <Stack space={{ xs: 2, md: 4 }}>
          <Box marginBottom={4}>
            <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
              nib Member Card
            </Heading>
          </Box>
          <Copy>
            Your nib member card is designed to make it easier to get the healthcare you need, when
            you need it. Simply take it along to your health appointments to let your healthcare
            provider know you're insured with nib. Please note that this is not a payment card.{' '}
          </Copy>
        </Stack>
      </ContentWrapper>
    </Wrapper>
  );
};

const StyledAccordionItem = styled(Accordion.Item)`
  h4 {
    color: ${colorTrueGreen};
  }
  p {
    color: ${colorTrueGreen};
  }
`;

const MemberCardAccordionContent = (): JSX.Element => {
  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
          nib Member Card FAQs
        </Heading>
        <Accordion>
          <StyledAccordionItem
            title="Who is eligible to have an nib member card?"
            titleComponent="p"
          >
            <Copy measure={false}>
              The card is available to anyone that is enrolled in the Te Nehenehenui Everyday Health
              Plan.
            </Copy>
          </StyledAccordionItem>

          <StyledAccordionItem title="How do I order a membership card? " titleComponent="p">
            <Copy measure={false}>
              Upon enrolment, we will send you your nib member card. You do not need to do anything.
            </Copy>
          </StyledAccordionItem>

          <StyledAccordionItem title="How do I use my nib member card?" titleComponent="p">
            <Stack space={4}>
              <Copy measure={false}>
                Your card lists the members of your whānau covered under your nib Te Nehenehenui
                Everyday Health Plan and your policy number.
              </Copy>
              <Copy measure={false}>
                Take it to your appointments to let your healthcare provider know you’re insured
                with nib for Everyday Services.
              </Copy>
              <Copy measure={false}>
                The provider may have access to our Provider Portal to claim on your behalf or you
                may need to pay for the treatment and then claim it back from nib.
              </Copy>
            </Stack>
          </StyledAccordionItem>

          <StyledAccordionItem title="What do I do if I lose my card? " titleComponent="p">
            <Padding bottom={4}>
              <Copy measure={false}>
                You can contact us for a replacement, let our Kaiārahi team know on{' '}
                <Link href="mailto:kaiarahi@nib.co.nz">kaiarahi@nib.co.nz</Link>.
              </Copy>
            </Padding>
          </StyledAccordionItem>
        </Accordion>
      </Stack>
    </Container>
  );
};

const NibMemberCardSection = (): JSX.Element => (
  <Section>
    <Stack space={7}>
      <MemberCardContent />
      <MemberCardAccordionContent />
    </Stack>
  </Section>
);

const CommonQuestionsSection = ({ policyDocument }: WithPolicyDocSectionProps): JSX.Element => (
  <Section background="sageGreen60">
    <Stack space={7}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
        Below are some common questions
      </Heading>
      <Heading color="trueGreen" size={{ sm: 5, md: 4, lg: 3 }} component="p">
        About eligibility
      </Heading>
      <Accordion>
        <StyledAccordionItem title="Who can get this cover?" titleComponent="p">
          <CopyDarkest measure={false}>
            Anyone registered with Te Nehenehenui who has a Maniapoto Membership Registration
            number. For kaumātua aged 65 and over, their first year of enrolment on this plan will
            be at no cost and thereafter, Te Nehenehenui will heavily subsidise future payments.
          </CopyDarkest>
        </StyledAccordionItem>

        <StyledAccordionItem title="Can I add my tamariki?" titleComponent="p">
          <CopyDarkest measure={false}>Yes, for an additional cost, if they are:</CopyDarkest>
          <ul>
            <li>Under 21 years old; and</li>
            <li>Dependent on you as their legal guardian. </li>
          </ul>
          <CopyDarkest measure={false}>Whāngai tamariki can also be covered. </CopyDarkest>
        </StyledAccordionItem>

        <StyledAccordionItem
          title="Can I add my partner if they’re not registered with Te Nehenehenui?"
          titleComponent="p"
        >
          <CopyDarkest measure={false}>
            Yes, your spouse or partner can join while they're in a relationship with you as a
            registered tribal member, this will be at a cost to you. A partner means your spouse or
            a person who lives with you in the nature of marriage.
          </CopyDarkest>
        </StyledAccordionItem>

        <StyledAccordionItem title="When is my cover free? " titleComponent="p">
          <ul>
            <li>
              If you are 65 or over when you enrol, your cover for the first full year is free.
              Thereafter your cover will be heavily subsidised.
            </li>
            <li>
              If you join before 65, you will receive a full year of free cover from your next
              policy anniversary after turning 65.
            </li>
          </ul>
        </StyledAccordionItem>

        <StyledAccordionItem
          title="How do I find my Maniapoto Membership registration number?"
          titleComponent="p"
        >
          <CopyDarkest measure={false}>You can get it three ways:</CopyDarkest>
          <ol>
            <li>
              Through Kākaho, Te Nehenehenui Tribal platform{' '}
              <Link href="https://tenehenehenui.iwi.nz/kakaho" target="__blank">
                https://tenehenehenui.iwi.nz/kakaho
              </Link>
            </li>
            <li>Call Te Nehenehenui on 0800 668 285 </li>
            <li>
              Email <Link href="mailto:tribalregister@tnn.co.nz">tribalregister@tnn.co.nz</Link>
            </li>
          </ol>
        </StyledAccordionItem>
      </Accordion>

      <Heading color="trueGreen" size={{ sm: 5, md: 4, lg: 3 }} component="p">
        About your cover
      </Heading>
      <Accordion>
        <StyledAccordionItem title="When do my benefit limits reset?" titleComponent="p">
          <CopyDarkest measure={false}>
            Your benefits reset each year on your policy anniversary (12 months from when your cover
            started).
          </CopyDarkest>
        </StyledAccordionItem>
        <StyledAccordionItem
          title="Do unused benefits carry over to next year? "
          titleComponent="p"
        >
          <CopyDarkest measure={false}>
            No, benefits don't carry over. Use the my nib app to keep track of your remaining
            limits.
          </CopyDarkest>
        </StyledAccordionItem>
        <StyledAccordionItem title="How do I know what's covered? " titleComponent="p">
          <CopyDarkest measure={false}>
            Check the{' '}
            <Link href={policyDocument?.file?.url} target="_blank">
              Te Nehenehenui Everyday Health Plan policy document
            </Link>{' '}
            for full details of what's covered.
          </CopyDarkest>
        </StyledAccordionItem>
      </Accordion>

      <Heading color="trueGreen" size={{ sm: 5, md: 4, lg: 3 }} component="p">
        About claiming
      </Heading>
      <Accordion>
        <StyledAccordionItem title="When can I start claiming?  " titleComponent="p">
          <Stack space={4}>
            <CopyDarkest measure={false}>
              When you join nib, we’ll send you your policy document and an acceptance certificate
              confirming information about your policy - like your start date, any premium you have
              to pay, and special terms that may apply. Once your cover starts, you can start
              claiming for eligible healthcare services. There may be some waiting periods that
              apply on certain benefits. To find out what waiting periods apply, check your policy
              document.
            </CopyDarkest>
            <CopyDarkest measure={false}>
              In some cases, you may have had special terms applied to your policy when you joined,
              for example the waiting periods have been waived. To find out if there have been
              special terms applied to your policy, check your acceptance certificate.
            </CopyDarkest>
          </Stack>
        </StyledAccordionItem>

        <StyledAccordionItem title="What's the easiest way to make a claim?" titleComponent="p">
          <CopyDarkest measure={false}>
            Use the my nib app - it's quick and simple. Just:
          </CopyDarkest>
          <ol>
            <li>Start your claim </li>
            <li>Take a photo of your invoice </li>
            <li>Add claim details </li>
            <li>Add your refund information </li>
            <li>Submit </li>
          </ol>
          <CopyDarkest measure={false}>
            Watch our helpful videos on{' '}
            <Link href={'https://youtu.be/XABb09azjg8'}>making a claim</Link> or{' '}
            <Link href={'https://youtu.be/JhnMUWmbQ50'}>
              requesting <NoWrap>pre-approval</NoWrap>
            </Link>
            .
          </CopyDarkest>
        </StyledAccordionItem>

        <StyledAccordionItem title="Can my healthcare provider claim directly? " titleComponent="p">
          <CopyDarkest measure={false}>
            Yes, if they're connected to our Provider Portal. Let us know which providers you
            usually visit and we can work on adding them to our network.
          </CopyDarkest>
        </StyledAccordionItem>
      </Accordion>
    </Stack>
  </Section>
);

const KoreroSection = (): JSX.Element => {
  return (
    <NotQuiteWarmWhiteColorSectionBecauseHaveToMatchAvailableImageColorThatsIsNotInMesh>
      <Wrapper>
        <BackgroundImageWrapper
          backgroundImage={korero}
          backgroundColor={WarmWhite90}
          backgroundSize="contain"
        />
        <ContentWrapper isOnRight={true}>
          <Stack space={{ xs: 2, md: 4 }}>
            <Box marginBottom={4}>
              <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
                Have a korero with us
              </Heading>
            </Box>
            <Copy>
              Your nib health insurance is there for you to use when you need it — and using it
              should be easy. We make our policy documents and claims process as simple as possible,
              but if you’re unsure about what you are covered for and what you can claim, just ask.
            </Copy>
            <Copy>
              At nib, we have a special team who work with our nib Toi Ora members like you.
            </Copy>
            <Copy>
              For any pātai you may have, our Kaiārahi team are available on{' '}
              <Link href="mailto:kaiarahi@nib.co.nz">kaiarahi@nib.co.nz</Link> or you can book an
              online virtual or in-person consultation{' '}
              <Link
                href={
                  'https://outlook.office365.com/book/KairahiConsultationBooking@nibholdings.onmicrosoft.com/'
                }
              >
                here
              </Link>
              .
            </Copy>
          </Stack>
        </ContentWrapper>
      </Wrapper>
    </NotQuiteWarmWhiteColorSectionBecauseHaveToMatchAvailableImageColorThatsIsNotInMesh>
  );
};

const FindAProviderSection = (): JSX.Element => (
  <Section>
    <Stack space={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
        Find a provider
      </Heading>
      <Copy measure={false}>
        Look for a GP, physiotherapist, or a dentist in your area with our First Choice Network
        search tool.
      </Copy>
      <PrimaryButton href={urlConstants.findAProvider}>Find a Provider</PrimaryButton>
    </Stack>
  </Section>
);

const Tenehenehenui = (props: NWOProps): JSX.Element => {
  const policyDocument = props?.data?.policy?.edges?.[0]?.node?.document;

  return (
    <>
      <Layout>
        <div>
          <Helmet>
            <title>{title} | nib</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <HeroPanelSection />
          <InsuranceOverviewSection />
          <WhatThePlanIncludesSection policyDocument={policyDocument} />
          <JoinUpNowSection />
          <NibAppSection />
          <NibMemberCardSection />
          <CommonQuestionsSection policyDocument={policyDocument} />
          <KoreroSection />
          <FindAProviderSection />
        </div>
      </Layout>
    </>
  );
};

export default metrics({ pageName: 'nwo' })(Tenehenehenui);
